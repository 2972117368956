<template>
  <v-sheet class="pa-4">
    <v-row no-gutters>
      <v-btn
        text
        color="primary"
        @click="$router.push({ name: 'CoreCompetencies' })"
      >
        <v-icon class="mr-2">mdi-arrow-left</v-icon>
        {{ $t("routes.core_competencies") }}
      </v-btn>
    </v-row>

    <v-divider class="my-4"></v-divider>

    <v-row no-gutters>
      <v-btn color="gradient-primary" depressed dark @click="toggleDialogWeight"
        >{{ $t("core_competencies.set_global_weights") }}
        <v-icon class="ml-2">mdi-settings</v-icon></v-btn
      >
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        outlined
        style="max-width: 250px"
        dense
      ></v-text-field>
    </v-row>

    <CoreCompetenciesForm
      :title="modalTitle"
      formType="weight"
      :modalMasterStatus="modalDialog"
      :toggleDialogMaster="toggleDialogWeight"
      :loadingMaster="loadingMaster"
      :listMaster="listMaster"
      :filter="{ school_year: 0, class: [] }"
      :tempData="tempData"
      multipleGrade
      @getValue="close()"
    />

    <v-divider></v-divider>

    <div>
      <v-data-table
        :headers="tableHeaders"
        :items="dataTable"
        :loading="loading"
        class="elevation-0"
      >
        <template v-slot:item.action="{ item }">
          <v-btn color="primary" icon small @click="openDetail(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <template #item.lesson="{item}">
          {{ stringSubject(item) }}
        </template>
      </v-data-table>
    </div>
  </v-sheet>
</template>

<script>
import { get_school_year_list, get_grade_list } from "@/api/admin/schoolClass";
import { get_weight } from "@/api/admin/academic/coreCompetencies";

export default {
  components: {
    CoreCompetenciesForm: () => import("./CoreCompetenciesForm")
  },
  data() {
    return {
      modalDialog: false,
      modalTitle: this.$i18n.t("core_competencies.set_global_weights"),
      listMaster: {
        schoolYearList: [],
        gradeList: []
      },
      loadingMaster: {
        schoolYear: false,
        grade: false,
        subject: false
      },
      loading: false,
      tableHeaders: [
        {
          text: this.$i18n.t("core_competencies.table.grade"),
          align: "left",
          value: "grade"
        },
        {
          text: this.$i18n.t("core_competencies.table.total_competencies"),
          align: "left",
          value: "total_competencies"
        },
        {
          text: this.$i18n.t("core_competencies.table.action"),
          sortable: false,
          value: "action",
          width: 150
        }
      ],
      dataTable: [],
      queryData: {
        school_year: "",
        grade: ""
      },
      tempData: null
    };
  },
  async created() {
    await this.getMasterList();
    this.initData();
  },
  methods: {
    close() {
      this.tempData = null;
      this.queryData.grade = "";
      this.modalTitle = this.$i18n.t("core_competencies.set_global_weights");
      this.initData();
    },
    openDetail(item) {
      this.loading = true;
      this.queryData.grade = item.id_grade;
      get_weight(this.queryData)
        .then(res => {
          if (res.data.code) {
            this.tempData = {
              grade: item.id_grade,
              data: res.data.data
            };
            this.modalDialog = true;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    toggleDialogWeight() {
      this.modalDialog = !this.modalDialog;
    },
    async getMasterList() {
      this.loadingMaster.schoolYear = true;
      this.loadingMaster.grade = true;

      const rSchoolYear = await get_school_year_list();
      const rGrade = await get_grade_list();
      this.listMaster.schoolYearList = rSchoolYear.data.data;
      this.listMaster.gradeList = rGrade.data.data;

      this.loadingMaster.schoolYear = false;
      this.loadingMaster.grade = false;
    },
    initData() {
      this.loading = true;
      get_weight(this.queryData)
        .then(res => {
          if (res.data.code) {
            this.dataTable = res.data.data;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    }
  }
};
</script>
